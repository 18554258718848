<template>
  <header class="header" :class="{'widescreen-padding': display.lgAndUp}">
    <div class="first-line">
      <div class="logo-section" @click="logoClick">
        <img src="/assets/logo-text.png" alt="Menu Icon" class="logo-text" />
      </div>
      <div class="logo-section">
        <div class="points">{{ userInfo?.points ? userInfo?.points : 0 }} P</div>
        <button class="menu-icon" @click="toggleMenu">
          <img :src="getProfilePicturePath" alt="Menu Icon" class="menu-image" />
          <img :src="getRankPicturePath" alt="Menu Icon" class="rank-image" />
        </button>
      </div>
      <div class="menu" v-if="isMenuVisible">
        <nav>
          <!--
          <a @click="goToHome">Home</a>
          <a href="#" @click="toggleMenu">About</a>
          <a href="#" @click="toggleMenu">Contact</a>
          <div class="divider"></div>
          -->
          <div class="version">v0.0.0</div>
          <div class="welcome">Welcome,</div>
          <div class="user-info">{{ userInfo?.fname }} {{ userInfo?.lname }}</div>
          <div class="divider"></div>
          <a @click="signOut" class="sign-out">Sign Out</a> 
        </nav>
      </div>
    </div>
  </header>
  <v-divider style="width: 100%;"></v-divider>
</template>

<script>
import { auth } from "@/firebaseConfig";
import userService from "@/api/userService";

import { useDisplay } from 'vuetify';

export default {
  data() {
    return {
      userInfo: null,
      errorMessage: "",
      isMenuVisible: false,
      display: {}
    };
  },
  async mounted() {
    this.display = useDisplay();
    this.userInfo = await userService.getUser(auth.currentUser.uid);
  },
  computed: {
    getProfilePicturePath() {
      try {
        return this.userInfo?.picture
          ? `/assets/profile-pictures/${this.userInfo.picture}`
          : '/assets/profile-pictures/pink-pp.png';
      } catch (error) {
        console.error("Image not found:", error);
        return '/assets/profile-pictures/pink-pp.png';
      }
    },
    getRankPicturePath() {
      if (this.userInfo?.points && this.userInfo?.points > 150) {
        return '/assets/ranks/rank1.png';
      } else if (this.userInfo?.points && this.userInfo?.points > 80) {
        return '/assets/ranks/rank2.png';
      }

      return '/assets/ranks/rank3.png';
    }
  },
  methods: {
    async logoClick() {
      this.$router.push('/threads/자기소개');
    },
    toggleMenu() {
      this.isMenuVisible = !this.isMenuVisible;
    },
    async signOut() {
      this.errorMessage = ""; // Clear any previous error messages
      try {
        await auth.signOut();
        console.log("Signed out successfully!");
        this.$router.push("/"); // Redirect to the login page
      } catch (error) {
        this.errorMessage = error.message;
        console.error("Sign out error:", error);
        this.isMenuVisible = false;
      }
    }
  },
};
</script>

<style scoped>
.header {
  height: 6vh;
  width: 100% - 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #000;
  color: white;
  padding-top: 13px;
  padding-right: 20px;
  padding-left: 20px;
}

.widescreen-padding {
  padding-right: 50px;
  padding-left: 50px;
}

.first-line {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}

.logo-section {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.logo-img {
  height: 28px;
  padding-left: 5px;
  padding-right: 8px;
}

.logo-text {
  height: 22px;
}

.points {
  font-size: 15px;
}

.menu-icon {
  border: none;
  border-radius: 50%; /* Makes the button circular */
  margin-left: 20px;
  width: 30px; /* Increased size to accommodate badge */
  height: 30px; /* Width and height should be equal for a perfect circle */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0; /* Remove any default padding */
  position: relative; /* Allows proper positioning of the badge */
}

.menu-image {
  width: 100%; /* Scale the image to fit within the wrapper */
  height: 100%;
  border-radius: 50%; /* Ensure the profile picture is circular */
  object-fit: cover; /* Maintains the aspect ratio of the image */
}

.rank-image {
  position: absolute; /* Position relative to the wrapper */
  bottom: -5px; /* Slightly outside the bottom edge */
  right: -5px; /* Slightly outside the right edge */
  width: 16px; /* Size of the badge */
  height: 16px;
  border-radius: 50%; /* Makes the badge circular */
  object-fit: cover; /* Maintains the aspect ratio of the badge image */
}

.menu {
  position: absolute;
  top: 50px;
  right: 10px;
  background-color: #000;
  color: white;
  padding: 20px;
  border-radius: 22px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 100;
  cursor: default;
}

nav a {
  display: block;
  color: white;
  text-decoration: none;
  padding: 10px 0;
  transition: color 0.3s;
}

nav a:hover {
  color: #ccc;
}

.divider {
  margin-top: 15px;
  margin-bottom: 20px;
  border-top: 2px solid #ccc;
}

.welcome {
  margin-left: 3px;
  font-weight: bold;
}

.user-info {
  margin-top: 5px;
  margin-left: 20px;
}

.version {
  text-align: right;
  font-size: 10px;
  padding-bottom: 12px;
}

.sign-out {
  color: black;
  background-color: orange;
  border-radius: 18px;
  text-align: center;
  cursor: pointer;
}
</style>
