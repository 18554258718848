// src/apiService.js
import { db } from "@/firebaseConfig";
import { setDoc, doc } from "firebase/firestore";

// Centralized error handling function
const handleError = (error) => {
  console.error("Firestore query error:", error);
  throw new Error(error.message);
};

export default {
  async getUser(uid) {
    try {
      const userDoc = await db.collection("users").doc(uid).get();
      return userDoc.exists ? userDoc.data() : null;
    } catch (error) {
      handleError(error);
    }
  },
  async postUser(uid, email, fname, lname, company, title, picture) {
    try {
      // Create user profile in Firestore
      await setDoc(doc(db, "users", uid), {
        uid: uid,
        email: email,
        fname: fname,
        lname: lname,
        company: company,
        title: title,
        picture: picture,
        tier: "0",
        createdAt: new Date().toISOString(),
        isActive: false,
        points: 0
      });
    } catch (error) {
      handleError(error);
    }
  },
};
